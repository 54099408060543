<template>
  <div class="all-height all-width">
    <div class="">
      <v-card loader-height="2" class="all-height all-width shadow-off" :loading="loading" :disabled="loading">
        <v-card-text class="">
          <div>
            <div class="d-flex mb-4">
              <span class="body-2">Below is the list of data received from Databricks pipeline</span>
              <v-spacer></v-spacer>
              <div class="text-left">
                <!-- tooltip-bottom-right position changed in file level -->
                <v-tooltip bottom content-class="tooltip-bottom-right">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on"
                      @click.stop="getDataProcessingStatus()">
                      <v-icon>mdi-timer-sand</v-icon>
                    </v-btn>
                  </template>
                  <span>Data Processing Status</span>
                </v-tooltip>
              </div>
              <div class="text-right">
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="refreshData()">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Refresh</span>
                </v-tooltip>
              </div>
            </div>
            <div v-if="data.length > 0" display="edit" :data="data">
              <lb-datatablenew :headers="headers" :items="formatedData"
                :enableslot="['action', 'receivedcount', 'bfcreceivedcount', 'processedcount', '__codename', 'status', 'ignoredcount', 'asperoverallstartlight','receivedcount_proforma', 'bfcreceivedcount_proforma', 'processedcount_proforma','all_pbt_sum_proforma','all_pbt_sum_actual']"
                v-if="data.length > 0" :loading="loading">
                <template v-slot:bfcreceivedcount="{ item }">
                  {{ item?.bfcreceivedcount }} {{ item.unit }}
                  <br />
                  <span v-if="item.bfcreceivedcount_ru != undefined" class="">{{ item.bfcreceivedcount_ru }} RUs</span>
                </template>
                <template v-slot:bfcreceivedcount_proforma="{ item }">
                  {{ item?.bfcreceivedcount_proforma }} {{ item.unit }}
                  <br />
                  <span v-if="item.bfcreceivedcount_ru_proforma != undefined" class="">{{ item.bfcreceivedcount_ru_proforma }} RUs</span>
                </template>
                <template v-slot:receivedcount="{ item }">
                  {{ item.receivedcount }} {{ item.unit }}
                  <br />
                  <span v-if="item.receivedcount_ru != undefined" class="">{{ item.receivedcount_ru }} RUs</span>
                </template>
                <template v-slot:receivedcount_proforma="{ item }">
                  {{ item.receivedcount_proforma }} {{ item.unit }}
                  <br />
                  <span v-if="item.receivedcount_ru_proforma != undefined" class="">{{ item.receivedcount_ru_proforma }} RUs</span>
                </template>
                <template v-slot:status="{ item }">
                  <v-chip v-if="item.status === 'Completed'" label small color="success">Completed</v-chip>
                  <v-chip v-else label small color="info">Pending</v-chip>
                </template>
                <template v-slot:__codename="{ item }">
                  <v-chip label small :color="item.__codecolor">{{ item.__codename || item.code }}</v-chip>
                </template>
                <template v-slot:all_pbt_sum_actual="{ item }">
                  <div v-if="item.all_pbt_sum_actual && item.all_ru_count">
                    {{ item.all_pbt_sum_actual }} {{item.unit}}
                    <br />
                    <span v-if="item.all_ru_count != undefined" class="">{{ $nova.formatNumber(item.all_ru_count) }} RUs</span>
                  </div>
                  <div v-else>
                    NA
                  </div>
                </template>
                <template v-slot:all_pbt_sum_proforma="{ item }">
                  <div v-if="item.all_pbt_sum_proforma && item.all_ru_count_proforma">
                    {{ item.all_pbt_sum_proforma }} {{item.unit}}
                    <br />
                    <span v-if="item.all_ru_count_proforma != undefined" class="">{{ $nova.formatNumber(item.all_ru_count_proforma) }} RUs</span>
                  </div>
                  <div v-else>
                    NA
                  </div>
                </template>
                <template v-slot:processedcount="{ item }">
                  {{ item.processedcount }} {{ item.unit }}
                  <br />
                  <span v-if="item.processedcount_ru != undefined" class="">{{ item.processedcount_ru }} RUs</span>
                </template>
                <template v-slot:processedcount_proforma="{ item }">
                  {{ item.processedcount_proforma }} {{ item.unit }}
                  <br />
                  <span v-if="item.processedcount_ru_proforma != undefined" class="">{{ item.processedcount_ru_proforma }} RUs</span>
                </template>
                <template v-slot:ignoredcount="{ item }">
                  {{ item.ignoredcount }}
                  <br />
                </template>
                <template v-slot:action="{ item }">
                  <div class="d-flex align-center">
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" color="" @click="viewDetails(item)">
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View Details</span>
                    </v-tooltip>
                  </div>
                </template>
              </lb-datatablenew>
            </div>
            <div v-else class="title grey--text pa-5 text-center">
              No Data
            </div>
          </div>
        </v-card-text>
      </v-card>

      <lb-dialog v-model="viewdetailsdialog" heading="Details" width="500">
        <template v-slot:body>
          <div>
            <v-chip class="mb-3" label small :color="selecteditem.__codecolor">{{ selecteditem.__codename ||
              selecteditem.code }}</v-chip>
            <div>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th scope="key">Key</th>
                    <th scope="value">Value</th>
                  </tr>
                </thead>
                <thead>
                  <template v-for="(v,k) in selecteditem.raw || {}">
                    <tr :key="k" v-if="k !== '_id'">
                      <td>{{ k }}</td>
                      <td>
                        <span
                          v-if="k === 'date' || k === 'created_at' || k === 'updated_at' || k === 'consol_last_run_time_in_bfc'">{{
                          $nova.formatDateTime(v) }}</span>
                        <span v-else>{{ v }}</span>
                      </td>
                    </tr>
                  </template>
                </thead>
              </v-simple-table>
            </div>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="dataprocessingstatusdialogue" heading="Data Processing Status" width="500"
        :loading='dataprocessingstatusloading'>
        <template v-slot:body>
          <div>
            <div class="text-right">
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="getDataProcessingStatus()">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </div>
            <div>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th scope="key">Collection Name</th>
                    <th scope="value">Status</th>
                    <th></th>
                  </tr>
                </thead>
                <thead>
                  <template v-for="(v,k) in dataProcessingData || {}">
                    <tr :key="k" v-if="k !== '_id'">
                      <td>
                        <div v-if="(v.batcheData?.length || 0) === 0">
                          <span>{{ k }}</span>
                        </div>
                        <div v-else class="d-flex align-center cursor-pointer" name="toggle-expand-collapse"
                          @click.stop="toggleSubCategory(k)">
                          <span>{{ k }}</span>
                          <div v-if="(v.batcheData?.length || 0) !== 0">
                            <v-icon class="ml-2" v-if="expandedsubbatches.indexOf(k) > -1">mdi-chevron-down</v-icon>
                            <v-icon class="ml-2" v-else>mdi-chevron-right</v-icon>
                          </div>
                        </div>
                      </td>
                      <td>
                        <v-chip label small v-if="(v.batcheData?.length || 0) === 0" color="success">Completed</v-chip>
                        <v-chip label small color="info" v-else>Pending</v-chip>
                      </td>
                    </tr>
                    <tr :key="k" v-if="expandedsubbatches.indexOf(k) > -1">
                      <v-simple-table dense>
                        <thead>
                          <tr>
                            <th scope="key">Batch time</th>
                            <th scope="value"> Pending Count</th>
                          </tr>
                        </thead>
                        <thead>
                          <template v-for="(batch,key) in v.batcheData || {}">
                            <tr :key="key" :style="{ backgroundColor: '#F5F5F7' }">
                              <td>{{ toISOConverter(batch._id) }}</td>
                              <td>
                                {{ batch.count || 0}}
                              </td>
                            </tr>
                          </template>
                        </thead>
                      </v-simple-table>
                    </tr>
                  </template>
                </thead>
              </v-simple-table>
            </div>
          </div>
        </template>
      </lb-dialog>


    </div>
  </div>
</template>

<script>

export default {
  name: 'varienceanalysis_dataprocessing',
  // props: ['props'],
  data: function(){
    return {
      loading: false,
      data: [],
      meta: {},
      viewdialog: false,
      viewloading: false,
      viewdata: {},
      colors: [],
      formatedData: [],
      selecteditem: {},
      viewdetailsdialog: false,
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
        },
        {
          type: "date",
          value: "date",
          text: "Batch Date",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "__codename",
          text: "Type",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "bfcreceivedcount",
          text: "As per BFC (Actual)",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "receivedcount",
          text: "As per Databricks (Actual)",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "processedcount",
          text: "As per Starlight (Actual)",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "all_pbt_sum_actual",
          text: "As per Overall Starlight (Actual)",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "bfcreceivedcount_proforma",
          text: "As per BFC (Proforma)",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "receivedcount_proforma",
          text: "As per Databricks (Proforma)",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "processedcount_proforma",
          text: "As per Starlight (Proforma)",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "all_pbt_sum_proforma",
          text: "As per Overall Starlight (Proforma)",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "ignoredcount",
          text: "Starlight Issue",
          type:'number',
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "status",
          text: "Status",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "center",
        },
      ],
      dataprocessingstatusdialogue : false,
      dataprocessingstatusloading: false,
      dataProcessingData : {},
      batchData : {},
      batchprocessingstatusdialogue:false,
      expandedsubbatches:[],

    }
  },
  components: {
  },
  activated() {
    this.refreshData();
  },
  mounted() {
    this.colors = this.$nova.getColors(10,0.2);
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.getData();
    },
    getData() {
      this.loading = true;
      this.axios.post("/v2/varianceanalysis/dataprocessing/get").then(dt => {
        if(dt.data.status === "success") {
          this.data = dt.data.data || [];
          this.formatData();
        }else throw new Error ("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    formatData() {
      console.log('formatdata')
      let ArrayTemp = [];
      let typelist = {};
      this.data.forEach((i, k) => {
        let dt = {
          raw: i,
          __key: k,
          __formatedkey: k,
          _id: i._id,
          indexno: k + 1,
          date: this.$nova.formatDateTime(i.date),
          code: i.code,
          bfcreceivedcount: 0,
          bfcreceivedcount_proforma:0,
          receivedcount: this.$nova.formatNumber(i.mongo_row_count) || 0,
          receivedcount_proforma: this.$nova.formatNumber(i.mongo_row_count_proforma) || 0,
          processedcount: this.$nova.formatNumber(i.processed_line_count) || 0,
          processedcount_proforma: this.$nova.formatNumber(i.processed_line_count_proforma) || 0,
          bfcreceivedcount_ru: undefined,
          bfcreceivedcount_ru_proforma: undefined,
          receivedcount_ru: undefined,
          receivedcount_ru_proforma:undefined,
          processedcount_ru: undefined,
          processedcount_ru_proforma: undefined,
          // ignoredcount: this.$nova.formatNumber(i.ignored_line_count) || 0,
          ignoredcount: Number(this.$nova.formatNumber(i?.ignored_line_count || 0)) || 0,
          status: i.status || "Pending",
          all_pbt_sum_actual: i.all_pbt_sum?  Number(this.limitToThreeDecimals(parseFloat(i.all_pbt_sum))) : 'NA',
          all_pbt_sum_proforma: i.all_pbt_sum_proforma?  Number(this.limitToThreeDecimals(parseFloat(i.all_pbt_sum_proforma))) : 'NA',
          all_ru_count: i.all_ru_count || undefined,
          all_ru_count_proforma: i.all_ru_count_proforma || undefined,
        }
        console.log(typeof dt.ignoredcount,dt.ignoredcount)
        if(!typelist[i.code]) typelist[i.code] = {name: this.getCodeName(i.code), color: this.colors[Object.keys(typelist).length]}
        dt.__codecolor = typelist[i.code].color
        dt.__codename = typelist[i.code].name
        if(dt.code === "import_user_security") {
          dt.bfcreceivedcount = Number(i?.bfc_user_count || 0) || 0
          dt.bfcreceivedcount_proforma = Number(i?.bfc_user_count || 0) || 0
          dt.receivedcount = Number(i?.mongo_user_count || 0) || 0
          dt.receivedcount_proforma = Number(i?.mongo_user_count || 0) || 0
          dt.processedcount = Number(i?.processed_user_count || 0)
          dt.processedcount_proforma = Number(i?.processed_user_count || 0)
          dt.unit = "Users"
        }
        else if(dt.code === "import_actualcycle_gl_data" || dt.code === "import_forecastcycle_gl_data") {
          dt.bfcreceivedcount = i.bfc_pbt_total? Number(this.limitToThreeDecimals(parseFloat(i.bfc_pbt_total))) : 0
          dt.bfcreceivedcount_proforma = i.bfc_pbt_total_proforma? Number(this.limitToThreeDecimals(parseFloat(i.bfc_pbt_total_proforma))) : 0
          dt.receivedcount = i.mongo_pbt_total? Number(this.limitToThreeDecimals(parseFloat(i.mongo_pbt_total))) : 0
          dt.processedcount = i.process_pbt_total? Number(this.limitToThreeDecimals(parseFloat(i.process_pbt_total))) : 0
          dt.receivedcount_proforma = i.mongo_pbt_total_proforma? Number(this.limitToThreeDecimals(parseFloat(i.mongo_pbt_total_proforma))) : 0
          dt.processedcount_proforma = i.processed_pbt_sum_proforma? Number(this.limitToThreeDecimals(parseFloat(i.processed_pbt_sum_proforma))) : 0
          dt.bfcreceivedcount_ru = this.$nova.formatNumber(i.bfc_ru_count) || 0
          dt.bfcreceivedcount_ru_proforma = this.$nova.formatNumber(i.bfc_ru_count_proforma) || 0
          dt.receivedcount_ru = this.$nova.formatNumber(i.mongo_ru_count) || 0
          dt.receivedcount_ru_proforma = this.$nova.formatNumber(i.mongo_ru_count_proforma) || 0
          dt.processedcount_ru = this.$nova.formatNumber(i.process_ru_count) || 0
          dt.processedcount_ru_proforma = this.$nova.formatNumber(i.processed_ru_count_proforma) || 0
          dt.unit = "PBT"
        }
        else if (dt.code === "import_soxid_actualcycle_gl_data") {
          dt.bfcreceivedcount = i.bfc_pbt_total? Number(this.limitToThreeDecimals(parseFloat(i.bfc_pbt_total))) : 0
          dt.bfcreceivedcount_proforma = i.bfc_pbt_total? Number(this.limitToThreeDecimals(parseFloat(i.bfc_pbt_total_proforma))) : 0
          dt.receivedcount = i.mongo_pbt_total? Number(this.limitToThreeDecimals(parseFloat(i.mongo_pbt_total))) : 0
          dt.processedcount = i.process_pbt_total? Number(this.limitToThreeDecimals(parseFloat(i.process_pbt_total))) : 0
          dt.receivedcount_proforma = i.mongo_pbt_total_proforma? Number(this.limitToThreeDecimals(parseFloat(i.mongo_pbt_total_proforma))) : 0
          dt.processedcount_proforma = i.processed_pbt_sum_proforma? Number(this.limitToThreeDecimals(parseFloat(i.processed_pbt_sum_proforma))) : 0
          dt.bfcreceivedcount_ru = this.$nova.formatNumber(i.bfc_ru_count) || 0
          dt.bfcreceivedcount_ru_proforma = this.$nova.formatNumber(i.bfc_ru_count_proforma) || 0
          dt.receivedcount_ru = this.$nova.formatNumber(i.mongo_ru_count) || 0
          dt.receivedcount_ru_proforma = this.$nova.formatNumber(i.mongo_ru_count_proforma) || 0
          dt.processedcount_ru = this.$nova.formatNumber(i.process_ru_count) || 0
          dt.processedcount_ru_proforma = this.$nova.formatNumber(i.processed_ru_count_proforma) || 0
          dt.unit = "PBT"
        }
        console.log(dt)
        //else if((Math.round(dt.bfcreceivedcount) == Math.round(dt.receivedcount)) && dt.bfcreceivedcount_ru == dt.receivedcount_ru && (!dt.all_pbt_sum || (Math.round(dt.all_pbt_sum) == Math.round(dt.bfcreceivedcount))) && (!dt.all_ru_count || (Math.round(dt.all_ru_count) == Math.round(dt.dt.bfcreceivedcount_ru))) && (Math.round(dt.receivedcount) == Math.round(dt.processedcount)) && dt.receivedcount_ru == dt.processedcount_ru) dt.status = "done"
        if (dt.code !== "import_user_security" && 
                  dt.bfcreceivedcount === (dt?.all_pbt_sum_actual || 0) && 
                  dt.receivedcount === dt.processedcount && 
                  dt.receivedcount_ru === dt.processedcount_ru &&
                  dt.bfcreceivedcount_proforma === (dt?.all_pbt_sum_proforma || 0) && 
                  dt.receivedcount_proforma === dt.processedcount_proforma && 
                  dt.receivedcount_ru_proforma === dt.processedcount_ru_proforma
                  && dt.ignoredcount === 0
                ) dt.status = "Completed"
        else if (dt.code === "import_user_security" && dt.receivedcount === dt.processedcount && dt.ignoredcount == 0) dt.status = "Completed"
        ArrayTemp.push(dt);
      });
      console.log(ArrayTemp)
      this.formatedData = ArrayTemp;
      console.log(this.formatedData)
    },
    getCodeName(code){
      if(code === "import_user_security") return "User Security"
      else if(code === "import_revenue_data") return "Client by client - Revenue"
      else if(code === "import_netsales_data") return "Client by client - Net Sales"
      else if(code === "import_forecastcycle_gl_data") return "Forecast Data"
      else if(code === "import_actualcycle_gl_data") return "Actual Data"
      else if(code === "import_soxid_actualcycle_gl_data") return "SOXID Actual Data"
    },
    viewDetails(item){
      this.selecteditem = {...item};
      this.viewdetailsdialog = true;
      // if(code === "import_user_security") return "User Security"
      // else if(code === "import_revenue_data") return "Client by client - Revenue"
      // else if(code === "import_netsales_data") return "Client by client - Net Sales"
      // else if(code === "import_forecastcycle_gl_data") return "Forecast Data"
      // else if(code === "import_actualcycle_gl_data") return "Actual Data"
    },
    getDataProcessingStatus(){
      this.dataprocessingstatusdialogue =true
      this.dataprocessingstatusloading = true;
      this.axios.post("/v2/varianceanalysis/dataprocessing/dataprocessingstatus").then(dt => {
        if(dt.data.status === "success") {
          this.dataProcessingData = dt.data.data[0] || [];
          console.log(this.dataProcessingData)
          Object.keys(this.dataProcessingData).forEach(key => {
            this.dataProcessingData[key]['expanded'] = false;
            console.log(this.dataProcessingData[key])
          });
        }else throw new Error ("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.dataprocessingstatusloading = false;
      })
    },
    toISOConverter(rawDate) {
      try {
        const date = new Date(rawDate);

        // Extract date components
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // January is 0
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Format the date as desired (e.g., YYYY-MM-DD HH:mm:ss)
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

        console.log(formattedDate); // Output: 2024-03-15 12:56:16
        return formattedDate
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
    toggleSubCategory(d) {
        let index = this.expandedsubbatches.indexOf(d);
        // console.log(index);
      if (index === -1) this.expandedsubbatches.push(d);
      else this.expandedsubbatches.splice(index, 1);
    },
    limitToThreeDecimals(num) {
      if (isNaN(num)) {
          return 0;
      }

      let numString = num.toString();
      let decimalIndex = numString.indexOf('.');

      if (decimalIndex === -1) {
          // No decimal point found, return the number as is
          return num.toFixed(3); // still need to return a string with 3 decimals
      }

      // Get the part before and after the decimal point
      let integerPart = numString.substring(0, decimalIndex);
      let decimalPart = numString.substring(decimalIndex + 1);

      // Limit the decimal part to 3 digits
      decimalPart = decimalPart.substring(0, 3);

      // Construct the final string and convert back to number
      let limitedNumString = `${integerPart}.${decimalPart}`;
      return parseFloat(limitedNumString).toFixed(3);
    }

  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>
/* .subs-icon{
  background-color: var(--v-primary-base) !important;
  color: white;
} */
.tooltip-bottom-right::before{
    left: 53%;
  }
</style>
