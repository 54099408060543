import { render, staticRenderFns } from "./DataProcessing.vue?vue&type=template&id=4095dc26&scoped=true&"
import script from "./DataProcessing.vue?vue&type=script&lang=js&"
export * from "./DataProcessing.vue?vue&type=script&lang=js&"
import style0 from "./DataProcessing.vue?vue&type=style&index=0&id=4095dc26&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4095dc26",
  null
  
)

export default component.exports